/* Global Styles */

* {
  font-family: 'hwt-artz', sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
  color: #616161;
}

body {
  margin: 0;
}

.footer {

}

.fixed-bottom-button {
  height: 60px;
  position:fixed;
  bottom: 10px;
  right: 10px;
  text-align: right;
  padding-right: 40px;
}

.container {
  display: flex;
  flex-direction: row;
}

router-outlet + *  {
  padding: 0 16px;
  width: 100%;
}

/* Text */

h1 {
  font-size: 60px;
}

h2 {
  font-size: 20px;
}

h1, h2 {
  font-weight: lighter;
}

p {
  font-size: 14px;
}

/* Hyperlink */

a {
  cursor: pointer;
  color: #006baf;
  text-decoration: none;
}

a:hover {
  opacity: 0.8;
}

/* Input */

input {
  font-size: 14px;
  border-radius: 2px;
  padding: 8px;
  border: 1px solid #BDBDBD;
}

input.search {
  font-size: 2em;
  border-radius: 2px;
  padding: 8px;
  border: 1px solid #BDBDBD;
}

input.login {
  font-size: 2em;
  border-radius: 2px;
  padding: 8px;
  border: 1px solid #BDBDBD;
}

label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
  display: block;
  text-transform: uppercase;
}

label.search {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 4px;
  display: block;
  text-transform: uppercase;
}

/* Button */
.button, button {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
  background-color: #1976d2;
  color: white;
  border: none;
}

.button:hover, button:hover {
  opacity: 0.8;
  font-weight: normal;
}

.button:disabled, button:disabled {
  opacity: 0.5;
  cursor: auto;
}

/* Top Bar */
app-top-bar {
  width: 100%;
  height: 68px;
  background-color: #006baf;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

app-top-bar h1 {
  color: white;
  margin: 0;
}

app-top-bar img {
  height: 60px;
}
